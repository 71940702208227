<template>
  <div>
    <!-- 面包屑区域 -->
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>统计图</el-breadcrumb-item>
        <el-breadcrumb-item>报告列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!-- 表格 -->
    <div class="main cardd">
      <el-table
        :data="tableData"
        :default-sort="{prop: 'uid', order: 'ascending'}"
        style="width: 100%"
      >
        <el-table-column prop="uid" label="ID" sortable width="195"></el-table-column>
        <el-table-column prop="username" label="账号"></el-table-column>
        <el-table-column prop="true_name" label="姓名">
          <template slot-scope="scope">
            <el-link type="warning">{{scope.row.true_name}}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="target" label="分析地址"></el-table-column>
        <el-table-column prop="create_time" label="下单时间"></el-table-column>
        <el-table-column prop="deduct_integral" align="center" width="80" label="花费积分">
          <template slot-scope="scope">
                <el-link type="danger">{{scope.row.deduct_integral}}</el-link>
            </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <el-tag type="primary">已支付</el-tag>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div>
        <Pagenation :size="15" :total="num" v-on:getPage="handleCurrentChange"/>
      </div>
    </div>
  </div>
</template>
<script>
import Pagenation from "@/components/pagenation";

export default {
  components: { Pagenation },

  data() {
    return {
      tableData: [],
      page: 0,
      num: 0
    };
  },
  mounted() {
    this.datas();
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    }
  },

  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.datas(this.page);
    },
    datas(val) {
      this.$axios
        .post("/get_intelligent_analysis_list", { page: val })
        .then(res => {
          this.tableData = res.data;
          this.num = res.count;
        });
    }
  }
};
</script>

<style  scoped>
.cardd {
  width: 95% !important;
  margin: 60px auto;
  padding-left: 20px;
}
</style>